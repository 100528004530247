<template>
  <v-dialog v-model="visible" transition="dialog-top-transition" width="500">
    <v-card>
      <v-card-title class="text-h5 primary">
        <p style="color: #ffffff">Property Out of Service Area</p>
      </v-card-title>
      <v-card-text>
        <p>
          This property is outside our current service area. This feature is
          coming soon!
        </p>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialog"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
const EVENT_NAME = "close-out-of-service-dialog";
export default {
  props: {
    value: Boolean,
  },
  computed: {
    visible: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit(EVENT_NAME);
      },
    },
  },
  methods: {
    closeDialog: function () {
      this.$emit(EVENT_NAME);
    },
  },
};
</script>
